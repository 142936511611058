







import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import OccupancyExtended from '@/modules/occupancy/components/occupancy-extended.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import OccupancyService, { OccupancyServiceS } from '@/modules/occupancy/occupancy.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import Day from '@/modules/common/types/day.type';

@Component({ components: { OccupancyExtended } })
export default class RatesPriceHistoryRate extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OccupancyServiceS) private occupancyService!: OccupancyService;
    @Inject(RatesServiceS) private ratesService!: RatesService;

    @Prop({
        type: Number,
        required: true,
    })
    private day!: Day;

    get occupancy() {
        const id = this.userService.currentHotelId || 0;
        const price = this.ratesService.getPrice(this.day);

        if (price) {
            return this.occupancyService.calculateOccupancyPercent([this.day, price, id]);
        }

        return this.occupancyService.calculateOccupancyPercent([0]);
    }
}

























































import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import Day from '@/modules/common/types/day.type';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import Currency from '@/modules/common/components/currency.vue';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import moment from 'moment';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-all.service';

export interface TableData {
    name: string,
    price: number,
    diff: number,
    myHotel?: boolean,
    borderColor?: string,
}

@Component({
    filters: { PercentFilter, PriceFilter },
    components: { Currency },
})
export default class RatesPriceHistoryTableAll extends Vue {
    @Inject(RatesPriceHistoryServiceS) ratesPriceHistoryService!: RatesPriceHistoryService;
    @Inject(RatesPriceHistoryAllServiceS) ratesPriceHistoryAll!: RatesPriceHistoryAllService;
    @Inject(RatesAllServiceS) ratesAllService!: RatesAllService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @Inject(RatesServiceS) ratesService!: RatesService;
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(ProvidersServiceS) providersService!: ProvidersService;

    @Prop({
        type: Number,
        default: null,
    })
    private tableDay!: Day | null;

    get compsetId() {
        return this.isChainPage ? this.$route.params.compsetId : null;
    }

    get currentHotelId() {
        return this.isChainPage ? Number(this.$route.params.hotelId) : this.userService.currentHotelId;
    }

    get isChainPage() {
        return this.$route.name && this.$route.name.toString().includes('chain.hotel.compset');
    }

    get day(): Day {
        return parseInt(this.$route.params.day, 10) as Day;
    }

    averagePrice(items: {[provider: string]: RatesDocumentItemAllModel}) {
        if (!items.average) {
            return 0;
        }
        return Number.isNaN(items.average.statistics.lowest) ? 0 : items.average.statistics.lowest;
    }

    priceDiff(myPrice: number, comparePrice: number) {
        if (myPrice === 0) return '';
        return this.ratesAllService.priceDiff(myPrice, comparePrice);
    }

    get scanDate() {
        return moment(this.ratesPriceHistoryService.lastScanDate).format('DD.MM');
    }

    get days() {
        const { dayIndex } = this.ratesPriceHistoryService;
        return !dayIndex ? 'Last update' : `-${dayIndex} days`;
    }

    get tableData() {
        const { providers } = this.ratesPriceHistoryAll;
        if (!providers) {
            return [];
        }

        const tablePriceHistoryData: TableData[] = providers.map((provider: string, index: number) => {
            const { lastScanDate, dayIndex } = this.ratesPriceHistoryService;
            if (lastScanDate === null) {
                return {} as TableData;
            }
            const providerItem = this.ratesPriceHistoryAll.getSuitableProviderByDay(dayIndex);

            if (!providerItem) {
                return {
                    name: this.providersService.getProviderLabel(provider) || this.$t(`filterSettings.provider.${provider}`),
                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.ratesPriceHistoryAll.getProviderGraphColor(index),
                } as TableData;
            }
            if (!providerItem[provider]) {
                return {
                    name: this.providersService.getProviderLabel(provider) || this.$t(`filterSettings.provider.${provider}`),
                    price: -1,
                    diff: -1,
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.ratesPriceHistoryAll.getProviderGraphColor(index),
                } as TableData;
            }

            const averagePrice = this.averagePrice(providerItem);

            return {
                name: this.providersService.getProviderLabel(provider) || this.$t(`filterSettings.provider.${provider}`),
                price: providerItem[provider].statistics && providerItem[provider].statistics.lowest,
                diff: (provider !== 'average')
                    ? this.priceDiff(providerItem[provider].statistics.lowest, averagePrice)
                    : -1,
                borderColor: provider === 'average'
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.ratesPriceHistoryAll.getProviderGraphColor(index),
                myHotel: provider === 'average',
            } as TableData;
        });

        return tablePriceHistoryData.sort((a, b) => b.price - a.price);
    }
}

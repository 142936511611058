





































































import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

@Component
export default class RatesPriceHistoryFilters extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(ClusterServiceS) clusterService!: ClusterService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get compSet() {
        const currentCompset = this.isClusterPage ? this.findCompsetByClusterService() : this.findComsetByCompsetService();

        return currentCompset ? `${currentCompset.name} (${currentCompset.type})` : '';
    }

    findComsetByCompsetService() {
        if (!this.compsetsService.compsets) {
            return null;
        }
        return this.compsetsService.compsets.find(compset => (
            compset.id === this.documentFiltersService.storeState.settings.compsetId
        ));
    }

    findCompsetByClusterService() {
        if (!this.compsetId || !this.hotelId) {
            return null;
        }

        return this.clusterService.getCompsetName(this.compsetId, this.hotelId);
    }

    get price() {
        return this.ratesService.storeState.settings.priceType;
    }

    get source() {
        return this.isClusterPage ? this.clusterService.currentProvider : this.ratesService.storeState.settings.provider;
    }

    get los() {
        return this.documentFiltersService.storeState.settings.los;
    }

    get pos() {
        return this.documentFiltersService.storeState.settings.pos;
    }

    get numberOfGuests() {
        return this.ratesService.storeState.settings.numberOfGuests;
    }

    get month() {
        return this.documentFiltersService.storeState.settings.month;
    }

    get roomType() {
        const { roomTypeId } = this.ratesService.storeState.settings;
        const { rooms } = this.roomTypesService;
        const currentRoom = rooms.find(room => room.id === roomTypeId);

        if (!currentRoom) {
            return '';
        }

        return currentRoom.name;
    }

    get mealType() {
        const { mealTypeId } = this.ratesService.storeState.settings;
        const { mealTypes } = this.mealTypesService;
        const currentMealType = mealTypes.find(mealType => mealType.id === mealTypeId);

        if (!currentMealType) {
            return '';
        }

        return currentMealType.name;
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }

    get compsetId() {
        return this.isClusterPage ? this.$route.params.compsetId : null;
    }

    get hotelId() {
        return this.isClusterPage ? Number(this.$route.params.hotelId) : null;
    }

    get isClusterPage() {
        return this.$route.name && this.$route.name.toString().includes('rates.cluster');
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CustomTooltip',_vm._b({attrs:{"tooltip-class":[
        'ph-float-tooltip',
        _vm.isAnalysisMode ? 'ph-float-tooltip--analysis' : '',
        _vm.isAllChannelsMode ? 'ph-float-tooltip--all' : '' ].join(' ')},scopedSlots:_vm._u([{key:"headline",fn:function(){return [_c('div',{staticClass:"ph-float-tooltip__headline"},[_c('div',{staticClass:"ph-float-tooltip__date-label"},[_vm._v(" "+_vm._s(_vm.date)+" ")])])]},proxy:true}])},'CustomTooltip',_vm.attrs,false),[_c('table',{staticClass:"ph-float-tooltip__table",attrs:{"width":"100%"}},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(_vm._s(_vm.isAllChannelsMode ? 'Source' : 'Name'))]),(!_vm.isAllChannelsMode)?_c('th',{attrs:{"width":"40"}},[_vm._v("Rank")]):_vm._e(),_c('th',{attrs:{"width":"100"}},[_vm._v(_vm._s(_vm.mainKey))]),(_vm.isAllChannelsMode)?_c('th',{attrs:{"width":"100"}},[_vm._v("Diff")]):_vm._e(),(_vm.isAnalysisMode)?_c('th',{attrs:{"width":"100"}},[_vm._v(_vm._s(_vm.compareKey)+" ")]):_vm._e(),(!_vm.isAllChannelsMode)?_c('th',[_vm._v("Room name")]):_vm._e()])]),_c('tbody',_vm._l((_vm.currentTableData),function(row){return _c('tr',{key:row.hotelId,class:{
                    'ph-float-tooltip__row': true,
                    'ph-float-tooltip__row--compset': row.isCompset,
                    'ph-float-tooltip__row--main-hotel': row.isMainHotel,
                    'ph-float-tooltip__row--hidden': row.isGraphHidden,
                }},[_c('td',[_c('div',{class:{
                            'graph-legend': true,
                            'graph-legend--hidden': row.isGraphHidden,
                            'graph-legend--transparent': row.isCompset,
                        },style:({
                            '--indicator-color': row.color,
                        })})]),_c('td',[_vm._v(" "+_vm._s(_vm._f("CutString")(row.hotelName))+" ")]),(!_vm.isAllChannelsMode)?_c('td',[_vm._v(" "+_vm._s(row.rank)+" ")]):_vm._e(),_c('td',[_vm._v(" "+_vm._s(row.priceString)+" ")]),(_vm.isAllChannelsMode)?_c('td',[_vm._v(" "+_vm._s(row.diff)+" ")]):_vm._e(),(_vm.isAnalysisMode)?_c('td',[_vm._v(" "+_vm._s(row.analysis ? row.analysis.priceString : '')+" ")]):_vm._e(),(!_vm.isAllChannelsMode)?_c('td',[_vm._v(" "+_vm._s(_vm._f("CutString")(row.roomName))+" ")]):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }







































































/* eslint-disable no-param-reassign */
import { Inject } from 'inversify-props';
import { Component, Prop } from 'vue-property-decorator';

import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import ClipText from '@/modules/common/filters/clip-text.filter';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';

import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DayTooltipTemplate from '@/modules/common/components/ui-kit/day-tooltip-template.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesCommonService, { RatesCommonServiceS } from '../../rates/rates-common.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS } from '../rates-price-history.service';
import PRICE from '../../rates/constants/price.enum';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS } from '../rates-price-history-all.service';

interface TableData {
    hotelId: number | string;
    hotelName: string;
    roomName: string;
    price: number;
    rank: string;
    priceString: string | number;
    color: string;
    diff?: string;
    isGraphHidden: boolean;
    isMainHotel: boolean;
    isCompset: boolean;

    analysis?: {
        price: number;
        priceString: string | number;
    }
}

@Component({
    extends: DayTooltipTemplate,
    filters: {
        CutString(value: string) {
            if (!value) return value;
            return ClipText(value, 20);
        },
    },
})
export default class RatesPriceHistoryTooltip extends DayTooltipTemplate {
    @Inject(RatesPriceHistoryServiceS)
    private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(ClusterCompsetsServiceS)
    private clusterCompsetsService!: ClusterCompsetsService;

    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(RatesPriceHistoryAllServiceS)
    private ratesPriceHistoryAll!: RatesPriceHistoryAllService;

    @Inject(ProvidersServiceS)
    private providersSerivce!: ProvidersService;

    @Prop({
        required: true,
        type: Object,
        default: () => ({}),
    })
    private hiddenGraphs!: { [hotelId: string]: boolean };

    @Prop({
        required: true,
        type: String,
        default: PRICE_SHOWN.SHOWN,
    })
    private priceShown!: PRICE_SHOWN;

    get date() {
        const { lastScanDate } = this.ratesPriceHistoryService;

        if (lastScanDate) {
            const d = new Date(lastScanDate);
            d.setDate(d.getDate() + this.day);

            const formatter = new Intl
                .DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

            return formatter.format(d);
        }

        return 'Price history';
    }

    get isActive() {
        const day = this.day as number;

        return !!this.focusElement && (day === 0 || !!day);
    }

    get isAnalysisMode() {
        if (!this.$route.name) return false;

        return this.$route.name.includes('.analysis');
    }

    get mainKey() {
        if (!this.isAnalysisMode) return 'Price';

        const v = String(this.ratesAnalysisFiltersService.mainCompareTitle!);
        return ClipText(v, 10);
    }

    get compareKey() {
        if (!this.isAnalysisMode) return '';
        const v = this.ratesAnalysisFiltersService.comparisonValues[0].name;

        return ClipText(v, 10);
    }

    get mainRooms() {
        return this.getRoomsByDatakey('main');
    }

    get compareRooms() {
        return this.getRoomsByDatakey('analysis');
    }

    get currentHotelId() {
        return +this.$route.params.hotelId;
    }

    get isClusterPage() {
        return (
            false
                || this.$route.name!.includes('cluster')
                || this.$route.name!.includes('chain')
        ) && !this.$route.name!.includes('.hotel');
    }

    get compsetId() {
        return this.isClusterPage
            ? this.$route.params.compsetId
            : null;
    }

    get isAllChannelsMode() {
        return this.ratesService.isAllChannelsMode;
    }

    get currentTableData() {
        return this.isAllChannelsMode
            ? this.tableDataAll
            : this.tableData;
    }

    get tableData() {
        const { toTableData } = this;
        const { hotels } = this.ratesPriceHistoryService;

        if (!hotels) return [];

        const roomList = hotels
            .map(toTableData.bind(this))
            .filter(row => !!row) as TableData[];

        if (this.compsetRow) {
            roomList.push(this.compsetRow);
        }

        roomList
            .sort((a, b) => +b.price - +a.price)
            .filter(row => !row.isCompset && row.price && row.price > 0)
            .forEach((row, i, arr) => {
                row.rank = String(arr.length - i);
            });

        const mainRoom = roomList.find(room => room.hotelId === this.currentHotelId);
        const compsetRoom = roomList.find(room => room.isCompset);
        const compsetIndex = compsetRoom ? roomList.indexOf(compsetRoom) : 0;

        let startIndex = compsetIndex - 3;
        let endIndex = compsetIndex + 4;

        const additionalEnd = -Math.min(0, startIndex);
        const additionalStart = Math.max(0, endIndex - roomList.length);

        startIndex -= additionalStart;
        startIndex = Math.max(0, startIndex);

        endIndex += additionalEnd;
        endIndex = Math.min(roomList.length, endIndex);

        const roomRange = roomList.slice(startIndex, endIndex);

        if (mainRoom) {
            const isMainRoomInRange = roomRange.includes(mainRoom);

            if (isMainRoomInRange) return roomRange;

            if (compsetRoom) {
                if (mainRoom.price > compsetRoom.price) {
                    roomRange.splice(0, 1, mainRoom);
                } else {
                    roomRange.splice(roomRange.length - 1, 1, mainRoom);
                }
            } else {
                roomRange.splice(roomRange.length - 1, 1, mainRoom);
            }
        }

        return roomRange;
    }

    get tableDataAll() {
        const { providers } = this.ratesPriceHistoryAll;

        if (!providers) {
            return [];
        }

        const getAveragePrice = (items: { [provider: string]: RatesDocumentItemAllModel}) => {
            if (!items.average) {
                return 0;
            }

            return Number.isNaN(items.average.statistics.lowest)
                ? 0
                : items.average.statistics.lowest;
        };

        const getPrice = (providerData: RatesDocumentItemAllModel) => true
            && providerData.statistics
            && providerData.statistics.lowest;

        const { lastScanDate } = this.ratesPriceHistoryService;

        if (lastScanDate === null) return [];

        const providersDataset = this.ratesPriceHistoryAll
            .getSuitableProviderByDay(-this.day);

        const averagePrice = providersDataset
            ? getAveragePrice(providersDataset)
            : 0;

        const tablePriceHistoryData: TableData[] = providers.map((provider: string, index: number) => {
            const hotelName = (this.providersSerivce.getProviderLabel(provider)
                || this.$t(`filterSettings.provider.${provider}`)) as string;

            let row = {
                hotelId: provider,
                hotelName,
                roomName: '---',
                priceString: '---',
                diff: '---',
                price: -1,
                color: provider === 'average'
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.ratesPriceHistoryAll.getProviderGraphColor(index),
                isGraphHidden: false,
                isMainHotel: false,
                isCompset: false,
                rank: '',
            } as TableData;

            const isProviderExists = providersDataset
                && providersDataset[provider];

            if (!isProviderExists) {
                return row;
            }

            const providerData = providersDataset[provider];

            const isMainHotel = provider === 'average';
            const isGraphHidden = false;
            const isCompset = false;
            const price = isMainHotel
                ? averagePrice
                : getPrice(providerData);

            const diff = !isMainHotel && price > 0
                ? ((price - averagePrice) / averagePrice) * 100
                : null;

            row = {
                ...row,
                isMainHotel,
                isGraphHidden,
                isCompset,
                hotelName,
                price,

                diff: diff === null
                    ? '---'
                    : `${diff.toFixed(0)}%`,

                color: isMainHotel
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.ratesPriceHistoryAll.getProviderGraphColor(index),
            };

            row.priceString = this.formatPrice(row.price);

            return row;
        });

        return tablePriceHistoryData.sort((a, b) => b.price - a.price);
    }

    get compsetRow() {
        const { ratesSettings } = this.ratesPriceHistoryService;

        const settings = {
            ...ratesSettings,
            priceShown: this.priceShown,
        } as RatesSettingsModel;

        const { price, compset } = this.getCompsetData(this.mainRooms, settings);

        if (!price) return null;

        const priceString = this.formatPrice(price);

        const row = {
            hotelId: -1,
            roomName: '',
            hotelName: String(this.$t(`filterSettings.compset_popup_table.${compset!.type}`)),
            rank: '',
            diff: '---',
            price,
            priceString,
            color: 'transparent',

            isGraphHidden: false,
            isCompset: true,
            isMainHotel: false,
        } as TableData;

        if (this.isAnalysisMode) {
            const compareCompsetData = this.getCompsetData(this.compareRooms, settings);

            row.analysis = {
                price: compareCompsetData.price || -1,
                priceString: this.formatPrice(compareCompsetData.price || -1),
            };
        }

        return row;
    }

    private getRoomsByDatakey(dataKey: 'main' | 'analysis') {
        const { comparisonValues, comparisonKey } = this.ratesAnalysisFiltersService;
        const scanDate = this.getCurrentScanDate(this.day);
        const key = dataKey === 'analysis'
            ? comparisonValues[0].name
            : dataKey;

        if (!scanDate) return {};

        const diffDays = dataKey === 'analysis' && comparisonKey === 'diffDays'
            ? comparisonValues[0].value as number
            : 0;

        this.ratesPriceHistoryService.setDataKey(key);

        return this.ratesPriceHistoryService
            .getSuitableRoomByDay(-this.day, diffDays);
    }

    private getCurrentScanDate(day: number) {
        const { lastScanDate } = this.ratesPriceHistoryService;

        if (!lastScanDate) return null;

        if (!day && day !== 0) return null;

        const currentScanDay = new Date(lastScanDate);
        currentScanDay.setDate(currentScanDay.getDate() + day);

        return currentScanDay;
    }

    private toTableData(hotelId: number): TableData | null {
        const { priceShown } = this;
        const isMainHotel = hotelId === this.currentHotelId;
        const roomData = this.mainRooms[hotelId];

        const hotelName = this.hotelsService.getHotelName(hotelId) || '---';
        const price = roomData
            ? this.ratesCommonService.switchPrice({ priceShown }, roomData)
            : -1;

        const { roomName = '' } = roomData || {};

        const priceString = this.formatPrice(price);
        const hotelColor = this.ratesPriceHistoryService
            .getHotelColor(hotelId, this.compsetId);

        const row = {
            hotelId,
            hotelName,
            roomName,
            price,
            rank: '',
            priceString,
            diff: '---',
            color: isMainHotel
                ? CURRENT_HOTEL_GRAPH_COLOR
                : hotelColor,

            isGraphHidden: this.hiddenGraphs[hotelId],
            isMainHotel,
            isCompset: false,
        } as TableData;

        if (priceString === '---') {
            row.roomName = '-';
        }

        if (this.isAnalysisMode) {
            row.analysis = this.getCompareDataFor(row);
        }

        return row;
    }

    private getCompsetData(rooms: HotelRooms, settings: RatesSettingsModel) {
        const competitorRooms = {
            ...rooms,
        };

        delete competitorRooms[this.currentHotelId!];

        const compset = this.compsetId
            ? this.clusterCompsetsService.getCompsetById(this.compsetId)
            : this.compsetsService.currentCompset;

        const price = this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset!.type, settings.priceShown);

        return { price, compset };
    }

    private getCompareDataFor(row: TableData) {
        const { hotelId } = row;
        const roomData = this.compareRooms[+hotelId];
        const price = this.ratesService.switchPrice(roomData);

        return {
            price: price || -1,
            priceString: this.formatPrice(price),
        };
    }

    private formatPrice(price: number | null) {
        if (this.isClusterPage) {
            this.ratesPriceHistoryService.currency = this.clusterRatesService
                .getCurrency(this.currentHotelId!);
        } else {
            this.ratesPriceHistoryService.currency = this.ratesService.currency;
        }

        const { currency } = this.ratesPriceHistoryService;
        const currencySymbol = this.helperService.currencySymbol(currency || '');

        if (price === null) return '---';

        switch (price) {
            case PRICE.SOLD_OUT:
                return 'Sold out';
            case PRICE.NA:
                return '---';

            default:
                return currency
                    ? currencySymbol + price.toFixed(2).replace(/\.0+$/, '')
                    : price.toFixed(2).replace(/\.0+$/, '');
        }
    }
}

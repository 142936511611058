import { render, staticRenderFns } from "./rates-price-history-filters.vue?vue&type=template&id=f6b823a4&scoped=true&"
import script from "./rates-price-history-filters.vue?vue&type=script&lang=ts&"
export * from "./rates-price-history-filters.vue?vue&type=script&lang=ts&"
import style0 from "./rates-price-history-filters.vue?vue&type=style&index=0&id=f6b823a4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6b823a4",
  null
  
)

export default component.exports















































































































import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import PopupEvents from '@/modules/events/components/popup-events.vue';
import Search from '@/modules/common/components/ui-kit/search.vue';
import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterRatesService, { ClusterRatesServiceS } from '@/modules/cluster/cluster-rates.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesPriceHistoryService, { RatesPriceHistoryServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history.service';
import Day from '@/modules/common/types/day.type';
// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import CURRENT_HOTEL_GRAPH_COLOR_RGB from '@/modules/common/constants/current-hotel-graph-color-rgb.constant';
import TogglePrices from '@/modules/common/modules/rates/components/toggle-prices.vue';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesPopupOccupancy from '@/modules/rates/components/rates-popup-occupancy.vue';
import RatesPopupDemand from '@/modules/rates/components/rates-popup-demand.vue';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RatesPriceHistoryAllService, { RatesPriceHistoryAllServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-all.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import RatesPriceHistoryFilters from './rates-price-history-filters.vue';
import RatesPriceHistoryHeader from './rates-price-history-header.vue';
import RatesPriceHistoryTable from './rates-price-history-table.vue';
import RatesPriceHistoryTableAll from './rates-price-history-table-all.vue';
import RatesPriceHistoryRate from './rates-price-history-rate.vue';
import RatesPriceHistoryTooltip from './rates-price-history-tooltip.vue';

const ZERO_DAY = '-  0  -';

@Component({
    components: {
        CustomGraph,
        CustomCheckbox,
        Search,
        PopupEvents,
        ModalWrapper,
        LoaderWrapper,
        RatesPriceHistoryRate,
        TogglePrices,
        RatesPopupOccupancy,
        RatesPopupDemand,
        RatesPriceHistoryHeader,
        RatesPriceHistoryFilters,
        RatesPriceHistoryTable,
        RatesPriceHistoryTableAll,
        RatesPriceHistoryTooltip,
    },
})
export default class RatesPriceHistoryPopup extends Vue {
    @Inject(HelperServiceS)
    private helperService!: HelperService;

    @Inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @Inject(UserServiceS)
    private userService!: UserService;

    @Inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @Inject(RatesFiltersServiceS)
    private ratesFiltersService!: RatesFiltersService;

    @Inject(RatesServiceS)
    private ratesService!: RatesService;

    @Inject(ClusterRatesServiceS)
    private clusterRatesService!: ClusterRatesService;

    @Inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @Inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @Inject(RatesPriceHistoryServiceS)
    private ratesPriceHistoryService!: RatesPriceHistoryService;

    @Inject(RatesPriceHistoryAllServiceS)
    private ratesPriceHistoryAll!: RatesPriceHistoryAllService;

    @Inject(RatesPriceHistoryCommonServiceS)
    private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    @Prop({ required: false })
    private day!: Day;

    @Prop({ type: Object })
    modalOptions!: DialogOptions;

    priceShown = PRICE_SHOWN.SHOWN;
    hiddenGraphs: { [key: string]: boolean } = {};

    tooltipFocusElement: HTMLElement | null = null;
    tooltipDay: Day | null = null;

    created(): void {
        if (!this.ratesPriceHistoryService.documentId) {
            this.$router.push(this.$route.path.split('price-history')[0]);
        }
    }

    get skeleton() {
        return this.ratesPriceHistoryService.storeState.loading.isLoading();
    }

    get isPriceSwitchDisabled() {
        return !this.ratesPriceHistoryService.isNetTotalAvailable || this.isNetTotalPricesSame;
    }

    get settings() {
        return this.ratesPriceHistoryService.ratesSettings;
    }

    get popupSettings(): RatesSettingsModel | null {
        if (!this.settings) return null;

        return { ...this.settings, priceShown: this.priceShown };
    }

    get document(): RatesDocumentModel | RatesDocumentAllModel | RatesCompsetMainModel | null {
        if (this.isClusterPage) {
            return this.clusterRatesService.currentDocument;
        }

        return this.ratesService.data;
    }

    get isNetTotalPricesSame() {
        if (!this.popupSettings) return true;
        if (!this.document) return true;

        return this.ratesCommonService.isNetTotalPricesSame(this.day, this.document as RatesDocumentModel);
    }

    get compareLabel() {
        if (this.isAnalysisPage) return '';

        const { filterComparisonName, comparisonValues } = this.ratesAnalysisFiltersService;

        return `${filterComparisonName} - ${comparisonValues[0].name}`;
    }

    get compsetId() {
        return this.isClusterPage
            ? this.$route.params.compsetId
            : null;
    }

    get hotelId() {
        return this.isClusterPage
            ? Number(this.$route.params.hotelId)
            : null;
    }

    get isClusterPage() {
        return false
            || this.$route.name!.startsWith('cluster')
            || this.$route.name!.startsWith('chain');
    }

    get isAnalysisPage() {
        return this.$route.path.includes('analysis');
    }

    get lastTooltipPos() {
        return this.ratesPriceHistoryService.storeState.lastTooltipPos;
    }

    get tableDay() {
        return this.ratesPriceHistoryService.storeState.dayIndex;
    }

    get choosenValues() {
        if (this.isAnalysisPage) return '';

        const { comparisonValues: values } = this.ratesAnalysisFiltersService;
        return values.map(e => e.name).join(', ');
    }

    get priceHistory() {
        const { day, hotelId, compsetId } = this;
        const { settings: DEFAULT_SETTINGS } = this.documentFiltersService.storeState;

        const compset = this.compsetsService.getCompset(compsetId);

        const documentSettings = this.isClusterPage && compset
            ? {
                ...DEFAULT_SETTINGS,
                compsetId: this.compsetId,
                los: compset.los.length ? compset.los[0] : null,
                pos: compset.mainPos || (compset.pos.length ? compset.pos[0] : null),
            }
            : DEFAULT_SETTINGS;

        return this.ratesPriceHistoryService
            .priceHistory(day, hotelId, compsetId, documentSettings);
    }

    get options() {
        return {
            maintainAspectRatio: false,
            elements: {
                line: {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                point: {
                    radius: 4,
                    backgroundColor: 'white',
                },
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#9B9B9B',
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#9B9B9B',
                    },
                    ticks: {
                        autoSkip: true,
                        padding: 10,
                        callback: value => `${this.currencySymbol}${value}`,
                    },
                }],
            },
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
        } as ChartOptions;
    }

    get currentHotelId() {
        if (this.isClusterPage) {
            return this.hotelId;
        }
        return this.userService.currentHotelId;
    }

    get chartData(): ChartData | null {
        const { documentId } = this.ratesPriceHistoryService;

        if (!this.priceHistory || !documentId) {
            return {
                labels: [],
                datasets: [],
            };
        }

        if (!this.tableDay) {
            this.ratesPriceHistoryCommonService.setTableDay();
        }

        const { hotels } = this.ratesPriceHistoryService;
        const { providers } = this.ratesPriceHistoryAll;
        const hiddenHotels = Object.keys(this.hiddenGraphs);

        if (!hotels || !providers) {
            return {
                labels: ['No Data'],
                datasets: [{}],
            };
        }

        const priceHistoryArray = this.priceHistory as [];

        const labels = priceHistoryArray
            .map((_: any, index: number) => {
                if (index === 0) {
                    return `-  ${index}  -`;
                }

                const day = index;
                return String(day).length === 1
                    ? `-0${day}`
                    : -day;
            })
            .reverse();

        if (this.filterAll) {
            const datasets: ChartDataSets[] = providers
                .map((provider: string, index) => ({
                    data: this.ratesPriceHistoryAll
                        .getPriceHistoryProvidersData(provider) || [],

                    borderDash: provider === 'average' ? [] : [8, 4],
                    borderColor: provider === 'average'
                        ? CURRENT_HOTEL_GRAPH_COLOR
                        : this.ratesPriceHistoryAll.getProviderGraphColor(index),

                    lineTension: 0,
                    borderJoinStyle: 'round',
                }));

            return {
                // NOTE: There is a bug in chartJs
                labels: labels.length
                    ? labels
                    : ['No Data'],
                datasets: datasets.length
                    ? datasets
                    : [{}],
            };
        }

        this.ratesPriceHistoryService.setDataKey('main');

        const datasets: ChartDataSets[] = hotels
            .map((hotel: number) => ({
                data: this.ratesPriceHistoryService.getPriceHistoryHotelPrices(hotel) || [],
                pointBorderWidth: 1,
                borderWidth: hotel === this.currentHotelId ? 3 : 2,
                borderColor: hotel === this.currentHotelId
                    ? CURRENT_HOTEL_GRAPH_COLOR
                    : this.ratesPriceHistoryService.getHotelColor(hotel, this.compsetId),
                lineTension: 0,
                borderJoinStyle: 'round',
                hidden: hiddenHotels.includes(hotel.toString()),
            }));

        let dataAnalysis: ChartDataSets[] = [];

        if (this.isAnalysisPage) {
            this.comparisonValues.forEach(item => {
                this.ratesPriceHistoryService.setDataKey(item.name);

                const data: ChartDataSets[] = hotels
                    .map((hotel: number) => ({
                        data: this.ratesPriceHistoryService.getPriceHistoryHotelPrices(hotel) || [],
                        pointBorderWidth: 1,
                        borderWidth: hotel === this.currentHotelId ? 3 : 2,
                        borderDash: [],
                        lineTension: 0,
                        borderColor: hotel === this.currentHotelId
                            ? `rgba(${CURRENT_HOTEL_GRAPH_COLOR_RGB},0.5)`
                            : `rgba(${this.ratesPriceHistoryService.getHotelColor(hotel, this.compsetId)},0.5)`,
                    }));
                dataAnalysis = [...dataAnalysis, ...data];
            });
        }

        const data = datasets
            .concat(dataAnalysis);
        return {
            labels,
            datasets: data,
        };
    }

    get comparisonValues() {
        if (this.isAnalysisPage) return [];

        return this.ratesAnalysisFiltersService.comparisonValues;
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get occupancy() {
        return this.ratesPriceHistoryService.getOccupancy();
    }

    get demand() {
        return this.ratesPriceHistoryService.getDemand();
    }

    get hideStatistics() {
        if (this.priceHistory && this.priceHistory.length > 0) {
            const data = this.priceHistory;
            const numberOfHotels = Object.keys(data[this.tableDay]).length;

            return Boolean(numberOfHotels);
        }
        return false;
    }

    get currencySymbol(): string | null {
        const { currency } = this.ratesPriceHistoryService;

        return currency
            ? this.helperService.currencySymbol(currency)
            : null;
    }

    public toggleGraph(hotel: { id: string }) {
        if (this.hiddenGraphs[hotel.id]) {
            this.$delete(this.hiddenGraphs, hotel.id);
            return;
        }

        this.$set(this.hiddenGraphs, hotel.id, true);
    }

    public mounted(): void {
        this.ratesPriceHistoryCommonService.setTableDay();
        this.updatePriceShown();
    }

    public handleTooltipClick(newPos?: string) {
        this.ratesPriceHistoryService.storeState.lastTooltipPos = newPos || null;
    }

    private updatePriceShown() {
        if (this.settings && !this.isNetTotalPricesSame) {
            this.priceShown = this.settings.priceShown;
        } else {
            this.priceShown = PRICE_SHOWN.SHOWN;
        }
    }

    public resetPopupData() {
        if (this.ratesPriceHistoryCommonService.documentId) {
            this.ratesPriceHistoryCommonService.setTableDay();
        }

        this.handleTooltipClick();
        this.ratesPriceHistoryService.storeState.loading.reset();
    }

    public setTooltipElement(el: HTMLElement) {
        this.tooltipFocusElement = el;
    }

    public setTooltipDay(label: string) {
        if (label === ZERO_DAY) {
            this.tooltipDay = 0 as Day;
            return;
        }

        this.tooltipDay = (label
            ? parseInt(label, 10)
            : null) as Day;
    }

    public setDay(label: string) {
        this.ratesPriceHistoryCommonService.setTableDay(label);
    }
}
